import React, { useEffect } from 'react';
import Button from '@atlaskit/button';
import { useSearch } from '../../../../SearchContext';
import { SearchBarContainer } from '../../styles';
import Tooltip from '@atlaskit/tooltip';
import { FaEraser } from 'react-icons/fa';
import { MdPrint, MdSave, MdSearch } from 'react-icons/md';
import { FaChevronDown } from "react-icons/fa";
import Visualizacao from '~/components/Visualizacao';

export const SearchBar: React.FC = () => {
  const {
    codTela,
    setShowImpressao,
    isUpdateCard,
    limit,
    setIsUpdateLimit,
    showTable,
    setOpenFields,
    getSearchFilter,
    isOpenFields,
    inputSearch,
    RefetchView,
    removeAllFilters,
    setView,
    filtersDaTela,
    handleColumns,
    setIsOpenModal,
    isUpdateLimit,
    setSortCampos,
    setIdFiltro,
    sortCampo,
    handleSetLimit,
    getValues,
    setValue,
    backSearch,
    openPopupView,
    loading,
  } = useSearch();

  const shouldClearFilter = getValues('shouldClearFilter');

  useEffect(() => {
    setValue('shouldClearFilter', false);
  }, [loading, setValue]);

  return (
    <SearchBarContainer>
      <input
        type="text"
        className="search"
        onChange={(e) => {
          setOpenFields(true);
          getSearchFilter(e.target.value);
        }}
        placeholder="Selecione um campo para busca personalizada..."
        onClick={() => setOpenFields(!isOpenFields)}
        // eslint-disable-next-line
        autoFocus
        value={inputSearch}
      />
      <div className="btns">
        <Button
          type="button"
          onClick={() => setOpenFields(!isOpenFields)}
          // eslint-disable-next-line
          title="Visualizar campos"
          className="btn-visualiza-campos"
        >
          <FaChevronDown />
        </Button>
        <Button
          type="button"
          onClick={() => {
            RefetchView();
            setValue('shouldOpenImpressao', true);
          }}
          title="Pesquisar"
        >
          <Tooltip position="right" content="CTRL + ALT + P">
            <MdSearch />
          </Tooltip>
        </Button>

        {(showTable === false && isUpdateCard === true) !== true &&
          shouldClearFilter &&
          !backSearch && (
            <Button
              type="button"
              onClick={() => {
                removeAllFilters();
                setValue('shouldClearFilter', false);
              }}
              title="Remover Filtros"
            >
              <Tooltip position="right" content="CTRL + ALT + L">
                <FaEraser />
              </Tooltip>
            </Button>
          )}
        {showTable && (
          <Visualizacao
            codTela={codTela}
            handleColumns={handleColumns}
            limit={limit}
            setLimit={(value) => handleSetLimit(value)}
            setView={setView}
            sortCampo={sortCampo}
            setIsUpdateLimit={setIsUpdateLimit}
            isUpdateLimit={isUpdateLimit}
            setSortCampos={setSortCampos}
            refetch={RefetchView}
            showTable={showTable}
            openPopupView={openPopupView}
          />
        )}
        {showTable && (
          <>
            <Button
              type="button"
              onClick={() => setShowImpressao(true)}
              title="Gerar Relatório"
            >
              <Tooltip position="right" content="CTRL + ALT + I">
                <MdPrint />
              </Tooltip>
            </Button>

            <Button
              type="button"
              isDisabled={!(filtersDaTela.length > 0)}
              onClick={() => {
                if (filtersDaTela.length > 0) {
                  setIdFiltro('');
                  setIsOpenModal(true);
                }
              }}
              title="Salvar"
            >
              <Tooltip position="right" content="CTRL + ALT + F">
                <MdSave />
              </Tooltip>
            </Button>
          </>
        )}
      </div>
    </SearchBarContainer>
  );
};
