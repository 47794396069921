import React from 'react';
import { useSearch } from '../../SearchContext';
import {
  ButtonReturnCards,
  ContainerButtonsHandler,
  ContainerOrder,
  DisplayFlex,
  SectionSearchContainer,
} from './styles';
import Tooltip from '@atlaskit/tooltip';
import { IoFilter } from "react-icons/io5";
import { BsPinFill } from 'react-icons/bs';
import { FaSquare } from 'react-icons/fa';
import { MdAdd, MdOutlineRemoveRedEye, MdSort, MdSearch } from 'react-icons/md';
import { SearchBar } from './components/SearchBar';
import { Filtros } from './components/Filtros';
import { TipoAcessoCards } from '../../components/TipoAcessoCards';

export const SectionSearch: React.FC = () => {
  const {
    isUpdateCard,
    View,
    fixedCard,
    isCardFixed,
    showTable,
    setOpenFields,
    isOpenFields,
    dataEdit,
    newData,
    orderTelas,
    setOpenOrderModal,
    onlyReport,
    orderModulo,
    setOpenOrderTelasModal,
    returnSearch,
    queryClient,
    page,
    abrePesquisa,
    limit,
    codTela,
    getOrderBy,
    columns,
    loadingTable,
    customButtons,
  } = useSearch();
  return (
    <SectionSearchContainer
      id="ClickedContainerFilter"
      onMouseLeave={() => setOpenFields(false)}
    >
      <h2 id="ClickedContainerFilter">
        {showTable && isUpdateCard && (
          <>
            <BsPinFill
              id="fixarcard"
              title={isCardFixed ? 'Desfixar Consulta' : 'Fixar Consulta'}
              style={{
                cursor: 'pointer',
                color: isCardFixed ? '#FF9900' : '#7C7C7C',
              }}
              onClick={() => fixedCard()}
            />
            <FaSquare
              style={{
                boxShadow:
                  'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
              }}
              color={dataEdit ? dataEdit.cor : ''}
            />
            {dataEdit ? dataEdit.des_card : ''}
          </>
        )}
        {showTable && !isUpdateCard && abrePesquisa && (
          <>
            <MdSearch
              color="#FFFFFF"
              style={{
                background: '#57069e',
                borderRadius: '3px',
                padding: '2px',
              }}
            />
            PADRÃO
          </>
        )}
        {showTable && (
          <>
            <MdOutlineRemoveRedEye
              color="#FFFFFF"
              style={{
                background: '#57069e',
                borderRadius: '3px',
                padding: '2px',
              }}
            />
            {View.toLocaleUpperCase()}
          </>
        )}
      </h2>

      <SearchBar />
      <Filtros />

      <div
        style={{
          zIndex: isOpenFields ? '-1' : '1',
          display: 'block',
        }}
      >
        <ContainerButtonsHandler id="ClickedContainerFilter">
          <DisplayFlex>
            {!onlyReport && (
              <Tooltip position="right" content="CTRL + ALT + N">
                <ButtonReturnCards
                  type="button"
                  onClick={() => {
                    const orderBy = getOrderBy(columns);

                    queryClient.setQueryData(`cacheLastSearch_${codTela}`, {
                      pag: page,
                      limite: limit,
                      orderBy,
                      shouldReturnSearch: showTable,
                    });
                    newData();
                  }}
                  className="addNew"
                >
                  <MdAdd />
                  Novo Registro
                </ButtonReturnCards>
              </Tooltip>
            )}
            {orderModulo && (
              <ContainerOrder>
                <Tooltip position="bottom" content="CTRL + ALT + O">
                  <ButtonReturnCards
                    onClick={() => setOpenOrderModal(true)}
                    className="ordenar"
                  >
                    Ordenar Módulos
                  </ButtonReturnCards>
                </Tooltip>
              </ContainerOrder>
            )}
            {orderTelas && (
              <ContainerOrder>
                <Tooltip position="right" content="CTRL + ALT + T">
                  <ButtonReturnCards
                    onClick={() => setOpenOrderTelasModal(true)}
                    className="ordenar"
                  >
                    <MdSort />
                    Ordenar Telas
                  </ButtonReturnCards>
                </Tooltip>
              </ContainerOrder>
            )}

            {customButtons}

            {!showTable && (
              <div style={{ width: '100%' }}>
                <TipoAcessoCards />
              </div>
            )}
          </DisplayFlex>

          {showTable ? (
            <Tooltip position="left" content="CTRL + ALT + R">
              <ButtonReturnCards
                animate={{ scale: 1 }}
                transition={{ duration: 1 }}
                onClick={returnSearch}
                disabled={loadingTable}
              >
                <IoFilter />
                Filtros personalizados
              </ButtonReturnCards>
            </Tooltip>
          ) : null}
        </ContainerButtonsHandler>
      </div>
    </SectionSearchContainer>
  );
};
